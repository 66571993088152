import React from 'react'
import BackToTop from 'composable/components/backtotop'
import { Box } from '@chakra-ui/react'

const BackToTopTastic: React.FC = () => {
  return (
    <Box height={12} width={12}>
      <BackToTop></BackToTop>
    </Box>
  )
}

export default BackToTopTastic
