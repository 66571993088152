import React, { useEffect, useState } from 'react'
import { Box, useBreakpointValue } from '@chakra-ui/react'
import { ChevronUpIcon } from '@chakra-ui/icons'

function BackToTop() {
  const [isVisible, setIsVisible] = useState(false)
  const isMobile = useBreakpointValue({ base: true, lg: false })
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    const toggleVisibility = () => {
      if (isMobile) {
        if (window.scrollY > 800) {
          setIsVisible(true)
        } else {
          setIsVisible(false)
        }
      } else {
        if (window.scrollY > 1000) {
          setIsVisible(true)
        } else {
          setIsVisible(false)
        }
      }
    }
    window.addEventListener('scroll', toggleVisibility)
    return () => window.removeEventListener('scroll', toggleVisibility)
  }, [])

  return (
    <>
      {isVisible && (
        <Box
          onClick={scrollToTop}
          position="fixed"
          bottom="80px"
          right={{ base: 1, md: 3 }}
          zIndex={999}
          height={12}
          width={12}
          borderRadius={'50%'}
          boxShadow="md"
          textAlign={'center'}
          cursor={'pointer'}
          transition={'0.3s easeInOut'}
          bg={'surface.primary'}
          _hover={{ bg: 'surface.highlight' }}
          _active={{ bg: 'text.primary !important', color: 'surface.primary' }}
          _focus={{ bg: 'surface.muted' }}
          aria-label="Back to top"
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              scrollToTop()
            }
          }}
        >
          <ChevronUpIcon boxSize={6} marginTop={'10px'} />
        </Box>
      )}
    </>
  )
}

export default BackToTop
